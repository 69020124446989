
.tabs {
    width: 100%;
    padding-left: 0px;
    display: flex;
    align-items: center;
    list-style: none;
    border-bottom: 2px solid $ls-color-gray--light;
}

.tab {
    display: block;
    padding: 10px 30px;
    color: $ls-color-gray--dark;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    .tab-link {
        color: $ls-color-gray--dark
    }

    &::after {
        width: 100%;
        background: $ls-color-gray--dark;
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0;
        height: 3px;
        opacity: 0;
        transition: 0.3s;
    }

    &:hover::after {
        display: block;
        content: '';
        opacity: 1;
    }

    .a-active{
        font-weight: 500;
        color: $ls-color-black--light !important;
    }

    .a-active::after {
        height: 3px;
        display: block;
        width: 100%;
        background: $ls-color-primary;
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0;
    }

    .router-link-exact-active.active::after {
        height: 3px;
        display: block;
        width: 100%;
        background: $ls-color-primary;
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0;
    }


    .router-link-exact-active.active {
        font-weight: 500;
        color: $ls-color-black--light !important;
    }

}
