// Payments
// -------------------------

@media(max-width: $x-small-breakpoint ) {

    .payments {

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }

        .page-header {
            margin-bottom: 5px;
        }

        .table-actions {
            margin-top: 0px !important;
        }

    }
}

.payment-create {
    .header-button-container {
        display: block;
    }

    .collapse-button-container {
        display: none;

        .collapse-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }

    }
}

@media(max-width: $x-small-breakpoint ) {

    .payment-create, .payment-index {

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }

        .header-button-container {
            display: none;
        }

        .collapse-button-container {
            display: block;
        }

    }

}