


/*===========================
     08.TESYIMONIAL css 
===========================*/

.testimonial-area{
    background-color: $gray;
}

.testimonial-content{
    
}

.single-testimonial-content{
    background-color: $white;
    @include box-shadow (0px 0px 11px 0px rgba(154,154,154,0.16));
    padding: 35px 35px 30px;
    position: relative;
    margin-top: 26px;
    margin-bottom: 35px;
    margin-left: 15px;
    margin-right: 15px;
    
    @media #{$xs}{
        padding: 35px 25px 30px;
    }
    
    &::before{
        position: absolute;
        content: '';
        width: 40px;
        height: 40px;
        background-color: $white;
        @include box-shadow (12px 12px 11px 0px rgba(154, 154, 154, 0.07));
        left: 50%;
        @include transform(translateX(-50%) rotate(45deg));
        bottom: -16px;
        border-radius: 5px;
    }
        
    & .testimonial-quote{
        position: absolute;
        top: -25px;
        left: 50%;
        @include transform(translateX(-50%));
        
        & img{}
    }
    & .testimonial-text{
        & .text{}
        & .holder-name{
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
        }
        & .sub-title{
            margin-top: 5px;
        }
    }
}


.testimonial-author{
    width: 80%;
    margin: 0 auto;
}

.single-testimonial-author{    
    margin: 30px 0;
    @include transition(0.3s);
    
    & img{
        border-radius: 50%;
        padding: 5px;
        background-color: $white;
        width: 95px;
        margin: 0 auto;
        @include transition(0.3s);
        cursor: pointer;
    }
}

.slick-current{
    &.single-testimonial-author{
        margin: 20px 0;
        
        & img{
            width: 115px;
            @include box-shadow (0px 3px 11px 0px rgba(196, 15, 124, 0.16));
        }
    }
}




