/*-----------------------------------------------------------------------------------

    Template Name: AppLand
    Template URI: uideck.com/templates/appland
    Support: https://uideck.com/support/
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/

@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'services';
@import 'about';
@import 'features';
@import 'screenshots';
@import 'testimonial';
@import 'price';
@import 'download';
@import 'footer';
