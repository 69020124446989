.customization {

    .prefix-input {
        max-width: 30%;
    }

    .form-section {
        padding: 8px 15px;
    }

    .invoice-customization-card {
        border: 1px solid #EBF1FA;border-radius: 5px;
    }

    @media (max-width: $x-small-breakpoint) {
        .address-customization-card {

            .address-fields-container {
                display: flex;
                flex-wrap: wrap;

                .fields-list {
                    border-right: 0px;
                }
            }

        }

        .tabs {

            .tab {
                padding: 10px 10px;
            }

        }

    }

}
