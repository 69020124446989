


/*===========================
       09.PEICING css 
===========================*/


.single-pricing{
    @include box-shadow (0px 0px 11px 0px rgba(154,154,154,0.16));
    padding: 25px 30px 30px;
    
    & .pricing-title{
        & .title{
            font-size: 25px;
            font-weight: 500;
        }
    }
    & .pricing-price{
        margin-top: 30px;
        
        & .price{
            font-size: 35px;
            font-weight: 600;
            color: $heading-color;
            line-height: 35px;
        }
        & .text{
            font-size: 18px;
            margin-top: 18px;
        }
    }
    & .pricing-list{
        margin-top: 30px;
        
        & .list{
            & li{
                line-height: 39px;
            }
        }
    }
    & .pricing-btn{
        margin-top: 28px;
        & .main-btn{}
    }
    
    &.pricing-active{
        position: relative;
        & .pricing-price{
            & .price{
                color: $theme-color;
            }
        }
    }
    
    &.pricing-active{
        position: relative;
        z-index: 5;
        
        & .pricing-shape{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            
            & img{
                width: 100%;
            }
        }
    }
}












