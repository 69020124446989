.item-unit-modal {

    .card-footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px;
    }

    .input-label {
        text-align: end;
        padding-right: 0;
        position: relative;
    }

    .required {
        position: absolute;
        // left: -10px;
        color: $ls-color-red;
    }

    .compound-tax-toggle {
        display: flex;
        align-items: center;
        margin-top: 9px;
    }

}

@media(max-width: $x-small-breakpoint ) {

    .base-modal {

        .item-unit-modal {
            width: 100vw;

            .input-label {
                text-align: left;
            }

        }

    }

}
