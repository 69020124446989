.item-select {

    .main-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px 15px 0 15px;

        .search-bar {
            display: flex;
            position: relative;
            width: 100%;
        }

        .no-data-label {
            display: flex;
            justify-content: center;
            color: $ls-color-gray;
            padding: 20px 20px;

            label {
                margin: 0 0 0 0;
            }
        }

    }

    .list {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .list-item {
            display: flex;
            justify-content: space-between;
            padding: 16px 23px;
            max-height: 56px;
            border-bottom: 1px solid rgba(185, 193, 209, 0.41);
            cursor: pointer;

            .title {
                font-size: 16px;
                color: black;
                cursor: pointer;
            }

            .price {
                font-size: 16px;
                color: black;
                cursor: pointer;
            }
        }

        .list-item:last-child {
            border: none;
        }

        .list-item:hover {
            background: $ls-color-gray--very-light;
        }

    }

}
