
.page-error-404 {

    background: $ls-color-secondary;

    .error-box {
        border-radius: 4px;
        left: 50%;
        margin: 0 auto;
        margin-bottom: 45px;
        padding: 20px;
        position: absolute;
        top: 50%;
        color: $white;
        transform: translate(-50%, -60%);
        width: 800px;
        background: #55547A;
    }

    h1 {
        font-size: 10rem;
        color: $ls-color-primary;
        margin-bottom: 2rem;
    }

    h5 {
        font-size: 2rem;
        color: $white;
        margin-bottom: 40px;
    }

}

.bg-yellow {
    background-color: $ls-color-primary;
    color: $ls-color-secondary;

    &:hover {
        background-color: darken($ls-color-primary, 5%);
    }
}
