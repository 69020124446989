// Subscription History statuses
.history-status-true{
    background: #5f8f56;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;

}

.history-status-false{
    background: #F25929;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;

}
// Invoice statuses

.inv-status-overdue {
    background: #F25929;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-paid {
    background: #5f8f56;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-printed {
    background: #5f8f56;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-unpaid {
    background: #F25929;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-sent {
    background: rgba(246, 208, 154, 0.4);
    font-size: 13px;
    color: #A96E1A;
    padding: 5px 10px;
}

.inv-status-viewed {
    background: #787ca9;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-completed {
    background: #5f8f56;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.inv-status-pending {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.inv-status-partially_paid {
    background: #E0EAF8 ;
    font-size: 13px;
    color: #16215B;
    padding: 5px 10px;
}

.inv-status-draft {
    background: #E0EAF8 ;
    font-size: 13px;
    color: #16215B;
    padding: 5px 10px;
}


// Estimate Statuses

.est-status-expired {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.est-status-accepted {
    background: #D5EED0;
    font-size: 13px;
    color: #354930;
    padding: 5px 10px;
}

.est-status-sent {
    background: rgba(246, 208, 154, 0.4);
    font-size: 13px;
    color: #A96E1A;
    padding: 5px 10px;
}

.est-status-viewed {
    background: #787ca9;
    font-size: 13px;
    color: #fff;
    padding: 5px 10px;
}

.est-status-pending {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.est-status-rejected {
    background: #16215B;
    font-size: 13px;
    color: #ffffff;
    padding: 5px 10px;
}

.est-status-draft {
    background: #F8EDCB;
    font-size: 13px;
    color: #6C432E;
    padding: 5px 10px;
}
