.sidebar-left {

    .menu-group {
        margin-bottom: 40px;

        &:first-child {
            margin-top: 29px;
        }
    }

    .menu-item {
        cursor: pointer;
        padding: 10px 0px 11px 0px;
        display: block;

        .menu-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $ls-color-black;
        }

        .menu-icon {
            margin-left: 25px;
            color: $ls-color-gray--dark;
            width: 30px;
            margin-left: 25px;
            display: inline-block;
            font-size: inherit;
            height: 1em;
            overflow: visible;
            vertical-align: -0.125em;
        }

    }

    .active {
        background-color: $ls-color-gray--very-light;
        position: relative;

        &::after {
            position: absolute;
            height: 100%;
            content: '';
            left: 0;
            top: 0;
            width: 3px;
            background-color: $ls-color-primary;
        }

        .menu-text {
            color: $ls-color-primary !important;
        }

        .menu-icon {
            color: $ls-color-primary !important;
        }

    }

}
