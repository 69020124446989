
.base-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(4,4,5,0.1);
    z-index: 10001;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-header {
        display: flex;
        justify-content: space-between;
        height: 70px;
        border-bottom: 1px solid $ls-color-gray--light;
        padding: 30px 30px 20px 30px !important;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 10px;
            background: $ls-color-primary;
        }

        .modal-heading {
            font-size: 17.5px;
            font-weight: 500;
            margin: 0;
        }
    }

    .modal-body {
        background: white;
        // box-shadow: $shadow-xl;
        position: relative;
        max-width: 815px;
        border: none;
        border-radius: 10px;
        overflow: hidden;
        padding: 0;
    }

    &.size-lg .modal-body {
        max-width: 815px
    }

    &.size-sm .modal-body {
        max-width: 400px
    }

    .close-icon {
        position: absolute;
        padding: 6px;
        top: 23px;
        right: 15px;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 20px;
        cursor: pointer;
    }

}


@media (max-width: $x-small-breakpoint) {
    .base-modal {

        .modal-body {
            height: 100%;
            overflow: scroll;
            border-radius: 0px;

        }

    }
}

