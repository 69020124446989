// Invoices - View
// -------------------------

.invoice-view-page {
    padding-left: 570px !important;

    .invoice-sidebar {
        width: 300px;
        height: 100vh;
        height: 100%;
        left: 240px;
        padding: 60px 0 10px;
        position: fixed;
        top: 0;
        width: 300px;
        z-index: 25;
        background: #FFFFFF;
    }

    .inv-search {
        background: $ls-color-gray--very-light !important;
    }

    .side-invoice {
        cursor: pointer;
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(185, 193, 209, 0.41);
        border-left: 3px solid transparent;

        &:last-child {
            margin-bottom: 98px;
        }

        &.router-link-exact-active {
           border-left: 3px solid $ls-color-primary;
           background-color: $ls-color-gray--very-light;
       }

        &:hover {
            background-color: $ls-color-gray--very-light;
        }

        .left {

            .inv-name {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                text-transform: capitalize;
                color: $ls-color-black;
                margin-bottom: 6px;
            }

            .inv-number {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $ls-color-gray--dark;
                margin-bottom: 6px;
            }

            .inv-status {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                padding: 2px 10px;
                display: inline-block;
            }

        }

        .right {

            .inv-amount {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
                text-align: right;
                color: $ls-color-black--light;
            }

            .inv-date {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                text-align: right;
                color: $ls-color-gray--dark;
            }

        }

    }

    .no-result {
        color: $ls-color-gray;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .side-header {

        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 15px;
        border-bottom: 1px solid rgba(185, 193, 209, 0.41);

        .inv-button {
            background: $ls-color-gray--very-light;
            border: 1px solid $ls-color-gray--light;
            box-sizing: border-box;
            color: $ls-color-gray;
            box-shadow: none !important;
        }

    }

    .side-content {
        overflow-y: scroll;
        height: 100%;
    }

    .invoice-view-page-container {
        display: flex;
        flex-direction: column;
        height: 75vh;
        min-height: 0;
        overflow:hidden;
    }

    .frame-style {
        flex: 1 1 auto;
        border: 1px solid $ls-color-gray;
        border-radius: 7px;
    }

    .inv-filter-fields-btn, .inv-filter-sorting-btn {

        &:focus {
            border-color: inherit;
            box-shadow: none;
            outline: none !important;
        }

    }

    .filter-container {
        margin-left: 12px;

        .filter-title {
            padding: 5px 10px;
            border-bottom: 1px solid rgba(185, 193, 209, 0.41);
            margin-bottom: 10px;
        }

        .filter-items {
            // margin-top: 10px;
            display: flex;
            padding: 4px 9px;
            cursor: pointer;

            &:first-child {
                margin-top: auto;
            }

        }

        .inv-label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 12px;
            text-transform: capitalize;
            color: $ls-color-black;
            margin-bottom: 6px;
            margin-left: 10px;
            cursor: pointer;
        }

        .base-input {
            width: 20%;
        }

        .dropdown-container {
            padding: 0px !important;
            left: auto;
            right: 0px;
            width: 155px;
        }

    }

    .filter-invoice-date {

        .vdp-datepicker {

            div {

                .vdp-datepicker__clear-button {
                    margin-left: -21px;
                    margin-top: 2px;
                    font-size: 20px;
                    font-weight: 800;
                }

            }

        }

    }

    .date-group {
        display: flex
    }

    .to-text {
        padding: 8px;
    }

}

@media (max-width: $small-breakpoint) {

    .invoice-view-page {
        padding-left: 310px !important;
    }

    .invoice-sidebar {
        transition: .2s all;
        left: 0px !important;
    }

}


