.tax-select {

    .main-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px 15px 0 15px;
    }

    .search-bar {
        display: flex;
        position: relative;
        width: 100%;
    }

    .no-data-label {
        display: flex;
        justify-content: center;
        color: $ls-color-gray;
        padding: 20px 20px;

        label {
            margin: 0 0 0 0;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        max-height: 112px;

        .list-item {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            max-height: 56px;
            border-bottom: 1px solid rgba(185, 193, 209, 0.41);
            cursor: pointer;

            label {
                font-size: 16px;
                margin: 0;
                font-weight: normal;
                color: #262630;
                line-height: 1.2;
                display: inline-block;
                cursor: pointer;
            }
        }

        .list-item:last-child {
            border: none;
        }

        .list-item:hover {
            background: $ls-color-gray--very-light;
            cursor: pointer;
        }

        .item-disabled {
            background: $ls-color-gray--very-light;
            cursor: not-allowed !important;
            opacity: 0.5;
            z-index: -10;
            pointer-events: none !important;
        }
    }
}
