.base-button {

    height: 40px;
    padding: 6px 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    white-space: nowrap;
    line-height: 14px;

    &.btn-lg {
        height: 45px;
        font-size: 15px;
        font-weight: 500;
        text-align: center
    }

    &.btn-sm {
        height: 34px;
        padding: 5px 15px;
        font-size: 12px;
        font-weight: 500;
        text-align: center
    }

    &.btn-danger {
        color: #ffffff !important;
    }

    &.btn-outline-danger:hover {
        color: #ffffff !important;
    }

    .icon-button {
        text-align: center;
    }

    .icon-left {
        margin-right: 10px !important;
    }

    .icon-right {
        margin-left: 10px !important;
    }

    &.btn-cursor-not-allowed {
        cursor: not-allowed !important;
    }

    .icon {
        margin-right: 5px;
    }
}
