// Items
// -------------------------

.items {

    .table-loader {
        padding: 25px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media(max-width: $x-small-breakpoint ) {

    .items {

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }

        .page-header {
            margin-bottom: 5px;
        }

        .table-actions {
            margin-top: 0px !important;
        }

    }

}

.item-create {

    .collapse-button {
        width: auto;
    }
}

@media(max-width: $x-small-breakpoint ) {
    .item-create {

        .collapse-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }

    }
}
