// Wizard
// -------------------------

.wizard {

    .logo-main {
        height: 50px;
    }

    .wizard-card {
        padding: 30px;
        width: 100%;
    }

    .row {
        margin-bottom: 27px;
    }

    .form-content {
        margin-top: 70px;
        display: flex;
        justify-content: center;
    }

    .form-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24.5px;
        line-height: 29px;
        color: #000000;
    }

    .form-desc {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $ls-color-gray--dark;
        width: 55%;
        margin-bottom: 30px;
    }

    .form-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .step-indicator {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 120px;
    }

    .indicator-line {
        border: 5px solid $ls-color-gray--light;
        border-radius: 5px;
        box-sizing: border-box;
        width: 520px;
        position: relative;
        display: flex;
        justify-content: space-around;
        margin-top: 60px;

        .center {
            position: absolute;
            top: -11px;
            width: 105%;
            display: flex;
            justify-content: space-between;
        }

        .steps {
            border-radius: 50%;
            float: left;
            border: 5px solid $ls-color-gray--light;
            padding: 0px 5px;
            height: 21px;
            width: 21px;
            background: $ls-color-gray--light;

            &.active {
                border: 5px solid #5851d8;
                background: #ffffff;
                border-radius: 50%;
                height: 21px;
                width: 21px;
            }

            &.completed {
                padding: 0px;
                background: #5851d8;
                border: 5px solid #5851d8;
                border-radius: 50%;
                height: 21px;
                width: 21px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-check {
                    font-style: normal;
                    font-weight: 900;
                    font-size: 12px;
                    /* line-height: 21px; */
                    color: #ffffff;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }
    }

    .upload-logo {
        position: absolute;
        right: 60px;

        .preview-logo {
            height: 50px;
            max-width: 300px;
            padding: 10px;
            border: 4px solid $ls-color-gray--light;
        }
    }

    .list-items {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        border: 1px solid $ls-color-gray--light;

        .left-item,
        .right-item {
            padding: 12px 10px;
            margin-bottom: 0;
        }

        .right-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .verified,
    .not-verified {
        height: 15px;
        width: 15px;
        background-color: $brand-success;
        display: inline-block;
        margin: 0 10px;
        border-radius: 50%;
    }

    .not-verified {
        background-color: $brand-danger;
    }

}
